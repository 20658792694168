<template>
	<v-container fluid>
		<!-- Create Modal -->

		<v-card>
			<v-card-title>
				<span class="headline">{{ formTitle }}</span>
			</v-card-title>

			<v-card-text>
				<v-form ref="form" enctype="multipart/form-data" lazy-validation>
					<!-- Preview Image -->
					<v-img v-if="imageUrl" :src="imageUrl" max-width="500"></v-img>
					<v-img
						v-else-if="$route.params.id"
						:src="article.image"
						max-width="500"
					></v-img>

					<!-- Image -->
					<v-file-input
						accept="image/png, image/jpeg, image/bmp"
						:placeholder="$t('image')"
						prepend-icon="mdi-camera"
						:label="$t('image')"
						@change="previewImage"
						@click:clear="clearImage"
						v-model="article.image"
					>
					</v-file-input>

					<!-- Title -->
					<v-text-field
						id="title"
						:label="$t('title')"
						name="title"
						type="text"
						v-model="article.title"
						:rules="titleRules"
						required
					></v-text-field>

					<!-- Categories -->
					<v-select
						v-model="article.categories"
						:items="categories"
						:label="$t('categories')"
						:rules="[requiredField]"
						multiple
						required
					></v-select>
					<!-- <v-row>
						<v-col v-for="(tag, i) in categories" :key="i" cols="2">
							<v-checkbox
								:label="tag"
								v-model="article.categories"
								:value="tag"
								hide-details
							>
							</v-checkbox>
						</v-col>
					</v-row> -->

					<!-- Short Description -->
					<v-textarea
						id="description"
						:label="$t('description')"
						name="description"
						type="text"
						v-model="article.description"
						rows="2"
						auto-grow
						:rules="descriptionRules"
						required
					></v-textarea>

					<!-- Content -->
					<wysiwyg v-model="article.content" />

					<v-checkbox :label="$t('published')" v-model="article.published">
					</v-checkbox>

					<!-- Error Messages -->
					<v-alert v-model="generalErr" type="error" dense dismissible>
						{{ $t('generalError') }}
					</v-alert>

					<!-- Success Messages -->
					<v-alert v-model="createSuccess" type="success" dense dismissible>
						{{ $t('createSuccess') }}
					</v-alert>
				</v-form>

				<!-- Loader -->
				<div v-if="isLoading" class="text-center">
					<v-progress-circular
						indeterminate
						color="primary"
					></v-progress-circular>
				</div>
				<small>*{{ $t('requiredFields') }}</small>
			</v-card-text>

			<v-card-actions>
				<v-btn color="red darken-2" dark @click="dialogDelete = true">
					{{ $t('delete') }}
				</v-btn>
				<v-spacer></v-spacer>
				<v-btn color="grey" dark @click="$router.go(-1)">
					{{ $t('back') }}
				</v-btn>
				<v-btn color="blue" dark @click="save">
					{{ $t('save') }}
				</v-btn>
			</v-card-actions>
		</v-card>

		<DeleteModal
			:dialogDelete="dialogDelete"
			:title="$t('confirmDelete')"
			@cancel="closeDelete"
			@ok="deleteConfirm"
		/>
	</v-container>
</template>

<script>
export default {
	name: 'ArticleCreate',
	components: {
		DeleteModal: () => import('@/components/DeleteModal'),
	},

	data() {
		return {
			url: process.env.VUE_APP_BASE_URL || 'http://localhost:5000/api/v1',
			isLoading: false,
			imageUrl: null,
			article: {
				image: null,
				title: '',
				description: '',
				content: '',
				categories: [],
				published: false,
			},
			categories: [
				'Хранене',
				'Дресировки',
				'Здраве',
				'Котки',
				'Кучета',
				'Птици',
				'Влечуги',
				'Гризачи',
			],
			titleRules: [v => !!v || this.$i18n.t('requiredField')],
			imageRules: [v => !!v || this.$i18n.t('requiredField')],
			categoriesRules: [v => !!v || this.$i18n.t('requiredField')],
			descriptionRules: [v => !!v || this.$i18n.t('requiredField')],
			dialogDelete: false,
			generalErr: false,
			createSuccess: false,
		};
	},
	computed: {
		formTitle() {
			return !this.$route.params.id
				? this.$t('createArticle')
				: this.$t('editArticle');
		},
	},
	methods: {
		requiredField(value) {
			if (value instanceof Array && value.length == 0) {
				return 'This field is required';
			}
			return !!value || 'This field is required';
		},
		previewImage(image) {
			this.imageUrl = URL.createObjectURL(image);
		},
		clearImage() {
			this.imageUrl = null;
		},
		async save() {
			this.$refs.form.validate();

			if (!this.$refs.form.validate()) {
				return;
			}

			this.isLoading = true;

			console.log('article: ', this.article);

			let formData = new FormData();

			formData.append(`file`, this.article.image);
			formData.append('title', this.article.title);
			formData.append('description', this.article.description);
			this.article.categories.forEach(category => {
				formData.append('categories', category);
			});
			formData.append('content', this.article.content);
			formData.append('published', this.article.published);

			if (this.$route.params.id) {
				try {
					await this.$http.put(
						`${this.url}/articles/${this.$route.params.id}`,
						formData,
					);

					this.createSuccess = true;
				} catch (error) {
					console.error(error);
					this.generalErr = true;
				} finally {
					this.isLoading = false;
				}
			} else {
				try {
					await this.$http.post(`${this.url}/articles`, formData);

					this.createSuccess = true;
				} catch (error) {
					console.error(error);
					this.generalErr = true;
				} finally {
					this.isLoading = false;
				}
			}
		},
		closeDelete() {
			this.dialogDelete = false;
		},
		deleteConfirm() {
			this.deleteArticle();
			this.dialogDelete = false;
		},
		async deleteArticle() {
			this.isLoading = true;

			try {
				await this.$http.delete(`${this.url}/articles/${this.article._id}`);

				this.$router.go(-1);
			} catch (error) {
				console.error(error);
			} finally {
				this.isLoading = false;
			}
		},
	},
	async mounted() {
		if (this.$route.params.id) {
			this.isLoading = true;

			try {
				const res = await this.$http.get(
					`${this.url}/articles/${this.$route.params.id}`,
				);

				Object.assign(this.article, res.data.data);
				this.article.image = null;
			} catch (error) {
				console.error(error);
			} finally {
				this.isLoading = false;
			}
		}
	},
};
</script>

<style lang="scss" scoped>
// Wysywyg
@import '~vue-wysiwyg/dist/vueWysiwyg.css';
</style>
